import React from "react";

export default () => (
    <div style={{ textAlign: "center" }}>
        <h1>Something went horribly wrong</h1>
        <p>
            Somewhere someone did something wrong and I am very sorry about
            that.
        </p>
        <p>
            Try returning to the <a href="https://davidweik.de">homepage</a>.
        </p>
        <p>
            If that didn't resolve your problem please contact me on Twitter:
            @weik_david
        </p>
    </div>
);
